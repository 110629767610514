
































import { computed, watchEffect } from '@vue/composition-api';

export default {
  props: {
    externDialog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const dialogVal = computed({
      get: () => props.externDialog,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    watchEffect(() => {
      if (!props.externDialog) {
        ctx.emit('toggle');
      }
    });

    function cancelExtern() {
      ctx.emit('click', 'closeExternshipDialog');
    }

    function confirmExtern() {
      ctx.emit('click', 'confirmExternshipDialog');
    }

    return { dialogVal, cancelExtern, confirmExtern };
  }
};
